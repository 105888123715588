<template>
  <div class='safety-attendance-data mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="100px" inline @submit.native.prevent size="small">
        <el-form-item label="用户姓名:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入用户姓名" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="月份:" prop="month">
          <el-date-picker v-model="keyWord.month" value-format="yyyy-MM" class="w100" type="month" placeholder="选择月份" :clearable="false" @change="getList(1)"></el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" class="warning" @click="attendanceExport()"><i class="icon icon-daochu"></i>导出</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%">
          <el-table-column prop="personName" label="姓名"/>
          <el-table-column prop="personNo" label="工号"/>
          <el-table-column prop="depaName" label="所属企业"/>
          <el-table-column prop="attendanceDay" label="日期"/>
          <el-table-column prop="weekDayStr" label="星期"/>
          <el-table-column prop="scheduleStatusName" label="排班状态"/>
          <el-table-column prop="commuteStartTimeStr" label="上班时间"/>
          <el-table-column prop="commuteEndTimeStr" label="下班时间"/>
          <el-table-column prop="checkInTimeStr" label="签到时间"/>
          <el-table-column prop="checkOutTimeStr" label="签退时间"/>
          <el-table-column prop="workingHours" label="工作时长(h)" width="100"/>
          <el-table-column :formatter="formatStatus" label="状态" fixed="right"/>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'safety-attendance-data',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
        month:new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2),//当前月份
      },
      //分页
      curr :1,//当前页
      limits :10,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableData :[],

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    breadCrumb,
  },
  watch:{
    // '$store.state.csType':function(val){
    //   vm.getList()
    // },
  },
  created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
  },
  mounted(){
    vm.getList()
    
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //导出
    async attendanceExport(){
      let dataObj = {
        'keyword':vm.keyWord.keyword,
        'searchYearMonth':vm.keyWord.month,
        'pageNo': vm.curr,
        'pageSize':vm.limits
      }
      const res = await apiUrl.attendanceExport(dataObj)
      if(!res.data) return;
      //fileName:下载的文件名
      var fileName = decodeURIComponent(res.headers["content-disposition"].split("=")[1])
      let blob = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
      var downloadElement = document.createElement("a");
      var href = window.URL.createObjectURL(blob); //常见下载的链接
      downloadElement.href = href;
      downloadElement.download = fileName; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href);  //释放blob对象
    },
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteHome(arr)
          })
          .catch(() => {})
      }else if(type=='reset'){//重置密码
        vm.$confirm('是否重置该账号密码','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).then(() => {
          vm.userResetPwd(item.id)
        })
        .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item);//新增或修改
        }
        if(type=='note'){//开通/关闭短信服务
          vm.changeSmsFlag(item)
        }
      }
    },
    //开通/关闭短信服务
    async changeSmsFlag(item){
      let dataObj = {
        'id':item.id,
        'openSmsFlag':item.openSmsFlag==1?2:1
      }
      const res = await apiUrl.changeSmsFlag(dataObj)
      if(res?.code==200){
        vm.$message.success(`${item.openSmsFlag==1?'关闭':'开通'}短信服务成功！`)
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteHome(arr);//删除
      }).catch(() => {});
    },
    //删除用户
    async deleteHome(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteHome(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //过滤状态
    formatStatus (row, column){
      return row.attendanceStatus=='NORMAL'?<span class="success">{row.attendanceStatusName}</span>
             :row.attendanceStatus=='LACK_CLOCK_IN'?<span class="error">{row.attendanceStatusName}</span>
             :row.attendanceStatus=='LATE'?<span class="warning">{row.attendanceStatusName}</span>
             :<span class="warning">{row.attendanceStatusName}</span>
    },
    //过滤时间
    formatLastTime(row,column){
      return row.lastLoginTime?formatTime(row.lastLoginTime,'Y-M-D h:m:s'):'-'
    },
    //过滤企业
    formatDepat(row,column){
      let arr = []
      if(row.departments && row.departments.length>0){
        for(var i in row.departments){
          arr.push(row.departments[i].depaName)
        }
      }
      return arr.join(',')||'-'
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange (val){
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      let dataObj = {
        'searchYearMonth':vm.keyWord.month,//年月
        'keyword': vm.keyWord.name,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits
      }
      const res = await apiUrl.attendanceListPage(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.safety-attendance-data{

}
</style>
